// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyBoW1jqE67_OtKWBhh57vXKAbE-CbXOBqI",
  authDomain: "gasbillnew2.firebaseapp.com",
  databaseURL: "https://gasbillnew2-default-rtdb.firebaseio.com",
  projectId: "gasbillnew2",
  storageBucket: "gasbillnew2.appspot.com",
  messagingSenderId: "489358888523",
  appId: "1:489358888523:web:385a4ca2efe24b449bc7ce",
  measurementId: "G-ZW0ZP1J1H5"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export { app };